<template>
  <div class="login">
    <h1 class="text-4xl font-bold text-center mb-16">Giving Circles</h1>
    <LoginForm />
    <NuxtLink class="text-gray-600 underline" to="/forgot-password">
      Forgot your password?
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
useHead({
  title: "Giving Circle - Login",
});
</script>

<style lang="scss" scoped>
.login {
  @apply bg-gray-100 min-w-full min-h-screen flex flex-col items-center justify-center px-4;
}
</style>
