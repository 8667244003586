<template>
  <form class="login-form" @submit.prevent="onSubmit">
    <div class="login-form__input-container">
      <UiTextInput
        class="top-rounded"
        :class="{ 'input-error': errorMessage }"
        v-model="email"
        placeholder="Email Address"
        icon="/images/icons/at-symbol.svg"
      />
      <UiTextInput
        class="bottom-rounded"
        :class="{ 'input-error': errorMessage }"
        type="password"
        v-model="password"
        placeholder="Password"
        icon="/images/icons/lock-close.svg"
      />
    </div>
    <UiButton
      type="submit"
      class="mt-6"
      label="Sign in"
      icon="/images/icons/enter.svg"
      :isLoading="isLoading"
    />
    <p class="text-sm text-red-500 mt-2 text-center h-5">
      {{ errorMessage }}
    </p>
  </form>
</template>

<script lang="ts" setup>
import { ref } from "vue";
const store = useStaffStore();

const email = ref("");
const password = ref("");
const isLoading = ref(false);

const errorMessage = ref("");

function onSubmit(): void {
  login();
}

async function login() {
  isLoading.value = true;

  try {
    await store.signIn(email.value, password.value);
    navigateTo("/dashboard");
  } catch (err: any) {
    errorMessage.value = err.message;
  }

  isLoading.value = false;
}
</script>

<style lang="scss" scoped>
.login-form {
  @apply flex flex-col w-full max-w-md;

  .login-form__input-container {
    @apply drop-shadow-lg flex flex-col;
  }
}
</style>

<style lang="scss" scoped>
.login-form__input-container {
  .top-rounded {
    .text-input {
      @apply rounded-br-none rounded-bl-none;
    }
  }
  .bottom-rounded {
    .text-input {
      @apply rounded-tr-none rounded-tl-none;
    }
  }
}
</style>
